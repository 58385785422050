<template>
    <b-card title="Demande de paiement">
      
      <b>Utilisateur :</b><br />
      {{ payout_request.user.name }} {{ payout_request.user.surname }}

      <br /><br />
      <b>Montant :</b><br />
      {{ payout_request.amount }}€

      <br /><br />
      <b>Date :</b><br />
      {{ payout_request.created_at }}

      <br /><br />
     
      

      <br /><br />
      <b-button v-b-modal.modal-success :disabled="payout_request.user.stripe_id==null" variant="success">Accepter</b-button>
      &nbsp;
      <b-button v-b-modal.modal-danger  variant="danger">Supprimer</b-button>

      <b-modal
        id="modal-success"
        cancel-variant="outline-secondary"
        cancel-title="Non"
        ok-variant="success"
        ok-title="Oui"
        @ok="acceptPayout()"
        modal-class="modal-success"
        centered
        title="Êtes-vous sûr ?"
      >
        <b-card-text>
          Réaliser le paiement de {{ payout_request.amount }}€ à {{ payout_request.user.name }} {{ payout_request.user.surname }} ?
        </b-card-text>
      </b-modal>

      <b-modal
      id="modal-danger"
      cancel-variant="outline-secondary"
      cancel-title="Non"
      ok-variant="danger"
      ok-title="Oui"
      @ok="deletePromotion()"
      modal-class="modal-danger"
      centered
      title="Êtes-vous sûr ?"
    >
      <b-card-text>
        Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer cette demande ?
      </b-card-text>
    </b-modal>

    </b-card>
    
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton
  },
  data() {
    return {
      payout_request:null,
      loaded:false,
      selectedItem:null
    }
  },
  methods:{
    async acceptPayout(){
      console.log('yess')
      this.loading=true
      try {
        await APIRequest.create_stripe_transfer(this.payout_request.user.stripe_id,this.payout_request.amount*100)

        try {

          await APIRequest.delete('payout-requests',this.payout_request.id)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Le paiement a bien été effectué !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.loading=false
          this.$router.push({name:'payouts'})
          
        } catch (error) {

          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        
      } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
    },
    async deletePromotion()
    {
      this.loading=true
      try {
        await APIRequest.delete('payout-requests',this.payout_request.id)
        
        this.loading=false
        this.$router.push({name:'payouts'})
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La demande a été supprimée !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    }
  },
  async mounted()
  {
    this.payout_request=await APIRequest.detail('payout-requests',this.$route.params.id)
    this.loaded=true
  }
}
</script>
